<script setup lang="ts">
import type { Api } from '~~/global'
import { replacePlaceholders } from '~/util/textFunction'
import type { Models } from '~/types/models'
import { useAuthStore } from '~/store/auth'
import { format, parseISO } from 'date-fns'
import { useDateFunctions } from '~/composables/DateFunctions'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
import AuthorCard from '~/components/cards/AuthorCard.vue'

const { defaultImages } = useVariantsDictionary()

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()

const Route = useRoute()

const {
    public: { origin, siteName, variant },
} = useRuntimeConfig()

const slug: string = Route.params.slug as string

const AuthStore = useAuthStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const headers = buildHeaders(AuthStore.SessionToken)

const { data: storeResult, error } = await useAsyncData('store-detail-index', async () => {
    return await $fetch<Api.Responses.Pages.StoreDetails | Api.Responses.ResourceNotFound>(
        endpoints.pages.store.path.replace('_SLUG_', slug),
        {
            method: 'GET',
            headers: headers,
            baseURL,
            params: {
                status: 'active',
            },
        },
    ).catch((e) => e.data || false)
})

if (error.value || !storeResult.value || storeResult.value.error) {
    throw createError({
        statusCode: storeResult.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            storeResult.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const info = storeResult.value.data

const defaultImage = defaultImages.store

const currentDates = currentDate()

const [title, description, h1, h2] = replacePlaceholders(
    [
        [':store', info.store.name || ''],
        [':last', info.store.last_discount || ''],
        [':count', String(info.store.all_count) || ''],
        [':coupons', String(info.store.coupons_count) || ''],
        [':offers', String(info.store.offers_count) || ''],
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':day', String(currentDates.day) || ''],
        [':site', siteName || ''],
        [':resume', info.extra_seo.resume || ''],
        [':coupontitle', info.extra_seo.coupon_title || ''],
    ],
    [
        info.store.seo?.title || '',
        info.store.seo?.description || '',
        info.store.seo?.h1 || '',
        info.store.seo?.h2 || '',
    ],
)
const metaInfo = {
    title,
    description,
    h1,
    h2,
}

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    ogImageSecureUrl: info.store.image?.url || '',
    ogImageUrl: info.store.image?.url || '',
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

onMounted(async () => {
    try {
        const secondaryInfo = await $fetch<
            Api.Responses.Pages.StoreSecondaryDetails | Api.Responses.ResourceNotFound
        >(endpoints.pages.store.secondaryData.replace('_SLUG_', slug), {
            baseURL,
            headers: buildHeaders(AuthStore.SessionToken),
            method: 'GET',
        })

        if (secondaryInfo.feedback === 'data_success') {
            secondaryData.similarStores = [...secondaryInfo.data.similar_stores]

            secondaryData.popularStores = [...secondaryInfo.data.popular_stores]

            secondaryData.relatedDiscounts = [...secondaryInfo.data.related_coupons]

            secondaryData.summary = {
                ...secondaryInfo.data.discount_summary,
                last_updated: info.store.updated_at,
            }
            activeTab.value = secondaryData.similarStores.length > 0 ? 1 : 2
        }
    } catch (e) {
        console.log('Fail on load secondary data: ', { e })
    }
})

const sectionsData: {
    offers: any[]
    coupons: any[]
} = reactive({
    offers: [
        ...(info.coupons.length ? info.offers.filter((o: any) => !!o.is_exclusive || !!o.code) : info.offers),
    ],
    coupons: [...(info.coupons || [])],
})

const updatedAt = computed(() => {
    return (!!info.store.updated_at && format(parseISO(info.store.updated_at), 'dd/MM/yyyy')) || ''
})

const secondaryData = reactive({
    paymentMethods: info.store.payment_methods?.data || [],
    summary: null as null | any,
    relatedDiscounts: null as null | Array<Models.Discount & { positionNumber: number }>,
    similarStores: null as null | Models.Store[],
    popularStores: null as null | Models.Store[],
})

const activeTab = ref(1)

const activeFaq = reactive({
    index: null as null | number,
    value: false,
})

const breadcrumbs = [
    {
        path: '/',
        title: $lang.views.storeDetails.home,
    },
    {
        path: `/${$lang.routes.brands}`,
        title: $lang.views.storeDetails.stores,
    },
    {
        title: info.store.name || '',
    },
]

const contents = computed(() => info.store.contents?.data || [])

const discountDescription = computed(() => {
    return contents.value[0]?.content?.replace(/<[^>]+>/g, '')
})

// @ts-ignore
useJsonld({
    '@context': 'http://schema.org',
    '@graph': [
        {
            '@type': 'Store',
            name: info.store.name,
            logo: info.store.image?.url,
            description: discountDescription.value,
            url: `${origin}/${variant !== 'cupohosting' ? `${$lang.routes.brands}/` : ''}${info.store.slug}`,
            image: info.store.image?.url,
            sameAs: info.store.url,
            address: $lang.views.homepage.schemeOrg.address,
        },
        {
            '@type': 'FAQPage',
            mainEntity: info.store.faqs?.data.map((value: any) => {
                return {
                    '@type': 'Question',
                    name: value.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: value.answer,
                    },
                }
            }),
        },
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                ...breadcrumbs
                    .filter((_, index, arg) => arg.length !== index + 1)
                    .map((value, index) => {
                        return {
                            '@type': 'ListItem',
                            position: index + 1,
                            item: `${origin}${value.path}`,
                            name: value.title,
                        }
                    }),
            ],
        },
    ],
})
</script>

<template>
    <div v-if="storeResult?.feedback === 'data_success'" class="store-details-index">
        <!-- <LayoutLinksWarning
            v-if="storeResult && info.store.commissions === 'on'"
            :title="$lang.views.storeDetails.comissions_on.header_link_text"
        />
        <LayoutLinksWarning
            v-if="storeResult && info.store.commissions === 'free'"
            :title="$lang.views.storeDetails.comissions_free.header_link_text"
        />
        <LayoutAlertMessage
            v-if="storeResult && info.store.header_messages && info.store.header_messages[0]"
            :info="info.store.header_messages[0]"
        /> -->
        <section class="store-details-index__cover">
            <div class="cover-content">
                <a
                    v-if="info.store.with_digidip"
                    :href="info.store.url"
                    rel="nofollow, noopener, noreferrer"
                    target="_blank"
                >
                    <picture class="cover-content__image">
                        <img
                            v-if="info.store.image"
                            :src="info.store.image.url || defaultImage"
                            :alt="info.store.name"
                            :title="info.store.name"
                            loading="lazy"
                            :onerror="`this.src='${defaultImage}'`"
                        />
                    </picture>
                </a>
                <a
                    v-else
                    :href="`/go/${$lang.routes.brands}/${info.store.id}`"
                    target="_blank"
                    rel="nofollow"
                >
                    <picture class="cover-content__image">
                        <img
                            v-if="info.store.image"
                            :src="info.store.image.url || defaultImage"
                            :alt="info.store.name"
                            :title="info.store.name"
                            loading="lazy"
                            :onerror="`this.src='${defaultImage}'`"
                        />
                    </picture>
                </a>
                <div v-if="info.store.seo" class="flex-grow">
                    <h1 class="cover-content__title">
                        {{ metaInfo.h1 }}
                    </h1>
                    <div class="cover-content__sub">
                        <h2>
                            {{ metaInfo.h2 }}
                        </h2>
                    </div>
                </div>
            </div>
        </section>

        <div class="store-details-index__content">
            <main class="main-content">
                <div :class="sectionsData.coupons.length > 0 ? 'space-y-6' : 'flex flex-col-reverse gap-y-6'">
                    <section class="main-content__coupons">
                        <ul v-if="sectionsData.coupons.length > 0" class="main-content__coupons-list">
                            <template
                                v-if="secondaryData.relatedDiscounts && secondaryData.relatedDiscounts.length"
                            >
                                <li
                                    v-for="(card, key) in secondaryData.relatedDiscounts"
                                    :key="'cta-' + key"
                                    :style="{ order: card.positionNumber }"
                                >
                                    <DCardAdmin
                                        v-if="card.is_admin"
                                        :store-info="info.store"
                                        :info="card"
                                        show-brand-logo
                                        :isRecommendedCoupon="true"
                                    />

                                    <DCardMain v-else :info="card" in-stores-route="true" />
                                </li>
                            </template>
                            <template v-if="sectionsData.coupons.length">
                                <li
                                    v-for="(cta, key) in info.cta_cards || []"
                                    :key="'cta-' + key"
                                    :style="{ order: cta.position }"
                                >
                                    <CTAHandler :cta="cta" />
                                </li>
                            </template>
                            <li
                                v-for="(item, key) in sectionsData.coupons"
                                :key="key"
                                :style="{ order: key + 1 }"
                            >
                                <DCardAdmin
                                    v-if="item.is_admin"
                                    :store-info="info.store"
                                    :info="item"
                                    :isMostUsedCoupon="item.is_recommended"
                                />

                                <DCardMain v-else :info="item" in-stores-route="true" />
                            </li>
                        </ul>

                        <MiscNoResults
                            v-else
                            :class="sectionsData.coupons.length > 0 ? '' : 'order-3'"
                            mode="discounts"
                            :button="{
                                text: $lang.views.storeDetails.best_coupons_here,
                                path: `/${$lang.routes.coupons}`,
                                icon: $assets.white.couponsMenu,
                                color: 'primary',
                            }"
                            :title="`${$lang.views.storeDetails.we_dont_have} <span class='text-site-primary'>${$lang.views.storeDetails.coupons}</span> ${$lang.views.storeDetails.in_the_store} ${info.store.name}, ${$lang.views.storeDetails.will_be_available}`"
                            :subtitle="$lang.views.storeDetails.best_coupons"
                        />
                    </section>

                    <section v-if="sectionsData.offers.length" class="main-content__offers">
                        <h2>{{ $lang.views.storeDetails.offers }}</h2>

                        <ul class="main-content__offers-list">
                            <template v-if="sectionsData.coupons.length === 0">
                                <li
                                    v-for="(cta, key) in info.cta_cards || []"
                                    :key="'cta-' + key"
                                    :style="{ order: cta.position }"
                                >
                                    <CTAHandler :cta="cta" />
                                </li>
                            </template>
                            <li
                                v-for="(item, key) in sectionsData.offers"
                                :key="key"
                                :style="{ order: key + 1 }"
                            >
                                <DCardMain :info="item" in-stores-route="true" />
                            </li>
                        </ul>
                    </section>
                </div>
                <LayoutAdminContent
                    v-if="$config.public.variant !== 'bchollos' && contents.length"
                    :content="contents[0]"
                    class="main-content__server"
                />

                <ul v-if="info.store.author" class="max-w-[940px] space-y-4">
                    <li>
                        <AuthorCard :info="info.store.author" />
                    </li>
                </ul>

                <section
                    v-if="
                        (secondaryData.similarStores && secondaryData.similarStores.length) ||
                        (secondaryData.popularStores && secondaryData.popularStores.length)
                    "
                    class="main-content__stores-tabs"
                >
                    <div class="stores-tabs">
                        <button
                            v-if="secondaryData.similarStores && secondaryData.similarStores.length"
                            class="stores-tabs__tab"
                            :class="{ active: activeTab === 1 }"
                            type="button"
                            @click="() => (activeTab = 1)"
                        >
                            {{ $lang.views.storeDetails.similar_stores }}
                        </button>

                        <button
                            v-if="secondaryData.popularStores && secondaryData.popularStores.length"
                            class="stores-tabs__tab"
                            :class="{ active: activeTab === 2 }"
                            type="button"
                            @click="() => (activeTab = 2)"
                        >
                            {{ $lang.views.storeDetails.popular_stores }}
                        </button>
                    </div>
                    <ul
                        v-if="
                            secondaryData.similarStores &&
                            secondaryData.similarStores.length &&
                            activeTab === 1
                        "
                    >
                        <li v-for="(store, index) in secondaryData.similarStores" :key="index">
                            <NuxtLink
                                no-prefetch
                                :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${store.slug}`"
                            >
                                <picture v-if="store.image">
                                    <img
                                        :src="store.image.url"
                                        :alt="store.name"
                                        :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                                    />
                                </picture>
                                <p>{{ store.name }}</p>
                            </NuxtLink>
                        </li>
                    </ul>
                    <ul
                        v-if="
                            secondaryData.popularStores &&
                            secondaryData.popularStores.length &&
                            activeTab === 2
                        "
                    >
                        <li v-for="(store, index) in secondaryData.popularStores" :key="index">
                            <NuxtLink
                                no-prefetch
                                :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${store.slug}`"
                            >
                                <picture v-if="store.image">
                                    <img
                                        :src="store.image.url"
                                        :alt="store.name"
                                        :onerror="`this.src='${defaultImage}'`"
                                    />
                                </picture>
                                <p>{{ store.name }}</p>
                            </NuxtLink>
                        </li>
                    </ul>
                </section>

                <SWidgetSummary
                    v-if="secondaryData.summary"
                    :title="$lang.views.storeDetails.discount_resume"
                    model="secondary"
                    :info="secondaryData.summary"
                    :store="info.store"
                />

                <SWidgetShare :store="info.store" />

                <!--  <div
                    id="comisiones"
                    class="main-content__commissions"
                    v-if="
                        (info && info.store.commissions === 'on') ||
                        (info && info.store.commissions === 'free')
                    "
                >
                    <div v-if="info && info.store.commissions === 'on'">
                        <h2 class="commissions__title">{{ $lang.views.storeDetails.comissions_on.title }}</h2>

                        <p
                            class="commissions__text"
                            v-for="text in $lang.views.storeDetails.comissions_on.texts"
                        >
                            {{ text.replaceAll(`_STORE_`, info.store.name) }}
                        </p>

                        <NuxtLink
                            no-prefetch
                            :to="$lang.views.storeDetails.comissions_on.link.path"
                            class="commissions__link"
                        >
                            {{ $lang.views.storeDetails.comissions_on.link.text }}
                        </NuxtLink>
                    </div>

                    <div v-if="info && info.store.commissions === 'free'">
                        <h2 class="commissions__title">
                            {{ $lang.views.storeDetails.comissions_free.title }}
                        </h2>

                        <p
                            class="commissions__text"
                            v-for="text in $lang.views.storeDetails.comissions_free.texts"
                        >
                            {{ text.replaceAll(`_STORE_`, info.store.name) }}
                        </p>

                        <NuxtLink
                            no-prefetch
                            :to="$lang.views.storeDetails.comissions_free.link.path"
                            class="commissions__link"
                        >
                            {{ $lang.views.storeDetails.comissions_free.link.text }}
                        </NuxtLink>
                    </div>
                </div>
 -->
                <!--                 <div id="trust-block" class="main-content__server">
                    <h2 class="mb-3 text-lg font-semibold">
                        {{ $lang.views.storeDetails.how_we_verify_coupons.title }}
                    </h2>

                    <p v-for="text in $lang.views.storeDetails.how_we_verify_coupons.texts" class="mb-3">
                        {{ text.replaceAll(`_STORE_`, info.store.name).replace('_UPDATED_', updatedAt) }}
                    </p>

                    <NuxtLink
                        no-prefetch
                        :to="$lang.views.storeDetails.how_we_verify_coupons.link.path"
                        rel="follow"
                        class="text-site-primary underline"
                        >{{ $lang.views.storeDetails.how_we_verify_coupons.link.text }}</NuxtLink
                    >
                </div>
 -->
                <SCardFaq
                    v-if="info.store.faqs && info.store.faqs.data.length"
                    title="Question"
                    :toggle="activeFaq"
                    :questions="info.store.faqs.data"
                />

                <section
                    v-if="info.store.articles && info.store.articles.length"
                    class="main-content__articles"
                >
                    <h2>Tips & Consejos de {{ info.store.name }}</h2>
                    <ul class="main-content__articles-list">
                        <li v-for="(article, index) in info.store.articles" :key="index">
                            <SCardArticle :article="article" />
                        </li>
                    </ul>
                </section>

                <template v-if="contents.length > 1">
                    <LayoutAdminContent
                        v-for="(content, index) in contents.slice(1)"
                        :key="index"
                        :content="content"
                    />
                </template>

                <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-black" class="cover-breadcrumbs" />
            </main>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.store-details-index {
    scroll-behavior: smooth;
    &__cover {
        /*  @apply h-[110px] lg:h-[140px]; */
        .cover-content {
            @apply container flex items-center gap-3 py-3 text-black md:max-w-[940px];
            &__image {
                @apply flex h-20 w-20 items-center overflow-hidden rounded-full border-4 border-white bg-white lg:h-28 lg:w-28;
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__title {
                @apply mb-1 text-xl font-medium sm:text-2xl;
            }
            &__sub {
                h2 {
                    @apply inline align-top text-sm font-medium sm:text-base;
                }
                button {
                    @apply inline-block w-5 align-sub;
                }
            }
        }
    }

    &__slider {
        @apply container mb-5;
    }

    &__content {
        @apply container mb-4 md:max-w-[940px];

        .main-content {
            @apply flex flex-col gap-y-4;
            &__stores-tabs {
                @apply overflow-hidden rounded-xl bg-white;
                .stores-tabs {
                    @apply flex border-b border-gray-200;
                    &__tab {
                        @apply flex-grow border-b-2 border-transparent px-3 py-2 font-medium lg:text-xl;

                        &.active {
                            @apply border-site-primary text-site-primary;
                        }
                    }
                }
                ul {
                    @apply flex flex-wrap gap-3 px-8 py-5;
                    li {
                        a {
                            @apply flex items-center space-x-2 rounded-lg bg-site-background px-6 py-1;
                            picture {
                                @apply block h-8 w-8;
                                img {
                                    @apply block h-full w-full object-contain;
                                }
                            }
                            p {
                                @apply font-medium;
                            }
                        }
                    }
                }
            }

            &__coupons,
            &__offers,
            &__expired,
            &__articles {
                @apply space-y-3;
                h2 {
                    @apply text-lg font-medium text-gray-800 sm:text-xl;
                }
            }
            &__server {
                @apply rounded-lg bg-white px-2 py-3 md:px-4 md:py-6 xl:px-8 xl:py-6;
            }

            &__articles-list,
            &__expired-list {
                @apply space-y-5;
            }
            &__coupons-list,
            &__offers-list {
                @apply flex flex-col;
                li {
                    @apply mb-5;
                }
            }
            &__recent-coupons {
                @apply mt-6 rounded-xl bg-white p-1.5 sm:p-5;
                .recent-coupons-title {
                    @apply mb-3 flex items-center text-lg font-semibold sm:text-xl;
                    picture {
                        @apply mr-2 block h-5 w-5;
                        img {
                            @apply h-full w-full object-contain;
                        }
                    }
                }
                .recent-coupons-table {
                    @apply w-full space-y-2 text-gray-800;
                    &__table-heading {
                        tr {
                            @apply flex w-full p-2;
                            td {
                                @apply flex gap-1 font-medium;
                                img {
                                    @apply h-3 self-center md:h-4;
                                }
                                &.left {
                                    @apply w-1/4 sm:w-1/6;
                                }
                                &.middle {
                                    @apply w-2/4 sm:w-4/6;
                                    &--hidden {
                                        @apply hidden md:block;
                                    }
                                }
                                &.right {
                                    @apply w-1/4 flex-none sm:w-1/6;
                                }
                            }
                        }
                    }
                    &__table-row {
                        @apply flex w-full p-2;
                        .left {
                            @apply w-1/4 font-semibold text-site-primary sm:w-1/6;
                        }
                        .middle {
                            @apply w-2/4 sm:w-4/6;
                        }
                        .right {
                            @apply w-1/4 flex-none sm:w-1/6;
                        }
                    }
                    &__row-list {
                        @apply space-y-3;
                    }
                }
            }

            &__commissions {
                @apply rounded-xl bg-white px-8 py-6;
                .commissions__title {
                    @apply mb-3 text-lg font-semibold;
                }
                .commissions__text {
                    @apply mb-3;
                }
                .commissions__link {
                    @apply cursor-pointer text-site-primary underline;
                }
            }
        }

        .sidebar-content {
            @apply mt-4 space-y-5;
        }
    }
}
</style>
